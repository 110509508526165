// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    website: path(ROOTS_DASHBOARD, '/website'),
    subadmin: path(ROOTS_DASHBOARD, '/subadmin'),
    category: path(ROOTS_DASHBOARD, '/category'),
    demo: path(ROOTS_DASHBOARD, '/demo'),
    viewpermission: (id)=> path(ROOTS_DASHBOARD, `/subadmin/Viewpermission${id}`),
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  agentblog: {
    blog:(id)=> path(ROOTS_DASHBOARD, `/blog/${id}`),
    viewpermission: (id)=> path(ROOTS_DASHBOARD, `/subadmin/Viewpermission${id}`),
    app: path(ROOTS_DASHBOARD, '/app'),
    editblog:(id)=> path(ROOTS_DASHBOARD, `/editblog/${id}`),
    addblog:(id)=> path(ROOTS_DASHBOARD, `/addblog/${id}`),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  dashboard: {
    blog:(id)=> path(ROOTS_DASHBOARD, `/blog/${id}`),
    viewpermission: (id)=> path(ROOTS_DASHBOARD, `/subadmin/Viewpermission${id}`),
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    addtravel: path(ROOTS_DASHBOARD, '/addtravel'),
    payment: path(ROOTS_DASHBOARD, '/payment'),
    addpayment: path(ROOTS_DASHBOARD, '/addpayment'),
    travel: path(ROOTS_DASHBOARD, '/travel'),
    client: path(ROOTS_DASHBOARD, '/client'),
    addclient: path(ROOTS_DASHBOARD, '/addclient'),
    commission: path(ROOTS_DASHBOARD, '/commission'),
    itineraries: path(ROOTS_DASHBOARD, '/itineraries'),
    edittravel:(id)=> path(ROOTS_DASHBOARD, `/edittravel/${id}`),
    addblog:(id)=> path(ROOTS_DASHBOARD, `/addblog/${id}`),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  weedowl: {
    root: path(ROOTS_DASHBOARD, '/weedowl'),
    product: path(ROOTS_DASHBOARD, '/weedowl/product'),
    new:  path(ROOTS_DASHBOARD, '/weedowl/singleproduct/add'),
    edit:(id) => path(ROOTS_DASHBOARD, `/weedowl/singleproduct/edit${id}`),
    category: path(ROOTS_DASHBOARD, '/weedowl/category'),
    user: path(ROOTS_DASHBOARD, '/weedowl/user'),
    order: path(ROOTS_DASHBOARD, '/weedowl/order'),
    offer: path(ROOTS_DASHBOARD, '/weedowl/offer'),
    offerproduct:(id)=> path(ROOTS_DASHBOARD, `/weedowl/offer/product${id}`),
    newsletter: path(ROOTS_DASHBOARD, '/weedowl/newsletter'),
    addnewsletter : path(ROOTS_DASHBOARD, '/weedowl/newsletter/add'),
    newsletterEdit : (id)=>path(ROOTS_DASHBOARD, `/weedowl/newsletter/edit${id}`),
    // edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
