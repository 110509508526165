/* eslint-disable no-unused-vars */

/* eslint-disable arrow-body-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useEffect, useMemo, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import toast, { Toaster } from 'react-hot-toast';
import { MaterialReactTable } from 'material-react-table';
import { useParams } from 'react-router';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Tooltip,
  IconButton,
  Avatar,
} from '@mui/material';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
// import { useParams } from 'react-router';

export default function Logos() {
  const url = 'https://api.dg.dev-iuh.xyz';
  const [data, setdata] = useState([]);
  const [dataone, setdataone] = useState([]);
  //   const navigate = useNavigate()
  const [uniqeidupdate, setuniqeidupdate] = useState([]);
  const [checkupdate, setcheckupdate] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [phone, setphone] = useState('');
  const [location, setlocation] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [image, setimage] = useState('');
  //   api/subadmin/permission/website
  const token = localStorage.getItem('accessToken');
  const handleClickOpen = () => {
    setOpen(true);
    setcheckupdate(false);
    setemail('');
    setphone('');
    setlocation('');
    setpassword('');
    setimage('');
    setuniqeidupdate('');
  };
  const handleClose = () => {
    setOpen(false);
  };

  // -----------------------------getdata-------------------
  const { id } = useParams();
  const fetchdata = () => {
    axiosInstance
      .get(`api/subadmin/permission/singlewebsite/${id}`)
      .then((response) => {
        // console.log(response,'response');
        // if (response.data.status === true) {
        setdata(response?.data?.data);
        // }
        // console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const Website = () => {
    axiosInstance
      .get(`api/subadmin/permission/website`)
      .then((response) => {
        if (response.data.status === true) {
          setdataone(response.data.data);
        }
        // console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    fetchdata();
    Website();
  }, []);
  // const filter = dataone?.filter((e)=>e?.id===id)
  // console.log(filter,'filter');

  const columns = useMemo(
    () => [
      {
        accessorKey: 'url',
        header: 'Url',
        size: 10,
        Cell: ({ row }) => (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={`${row?.original?.url}/?websiteid=${row?.original?.id}&agentid=${row?.original?.permission?.subadmin_id}`}
            target="_blank"
          >
            {`${row?.original?.url}/?websiteid=${row?.original?.id}&agentid=${row?.original?.permission?.subadmin_id}`}
          </a>
        ),
      },

      {
        accessorKey: 'image',
        header: 'Logo',
        size: 100,
        Cell: ({ row }) => {
          const imageUrl = `https://api.dg.dev-iuh.xyz/${row?.original?.image}`;

          return <Avatar alt="Cindy Baker" src={imageUrl} />;
        },
      },
      //   {
      //     accessorKey: 'phone',
      //     header: 'Phone',
      //     size: 150,
      //   },
      //   {
      //     accessorKey: 'location',
      //     header: 'Location',
      //     size: 150,
      //   },
      //   {
      //     accessorKey: 'email',
      //     header: 'email',
      //     size: 200,
      //   },

      //   {
      //     accessorKey: 'action',
      //     header: ' permissions',
      //     size: 200,
      //     Cell:({row})=>{
      //       const id = row.original.id;
      //       return(
      //         <Box sx={{ display: 'flex', justifyContent:'center' }}>
      //           <Tooltip arrow placement="left" title=" permissions">
      //           <IconButton >
      //               <VisibilityIcon onClick={() => handleviewproduct(id)} />
      //             </IconButton>
      //           </Tooltip>
      //         </Box>
      //       )
      //     }
      //   },
      // {
      //   accessorKey: 'action',
      //   header: 'Action',
      //   size: 100,
      //   Cell: ({ row }) => {
      //     const id = row.original.id;
      //     return (
      //       <Box sx={{ display: 'flex', gap: '1rem' }}>
      //         <Tooltip arrow placement="left" title="Edit">
      //           <IconButton onClick={() => handleEdit(row.original)}>
      //             <EditIcon />
      //           </IconButton>
      //         </Tooltip>
      //         <Tooltip arrow placement="right" title="Delete">
      //           <IconButton color="error" onClick={() => handledelete(id)}>
      //             <DeleteIcon />
      //           </IconButton>
      //         </Tooltip>
      //       </Box>
      //     );
      //   },
      // },
    ],
    []
  );
  // ---------viewpage-------------
  //   const handleviewproduct = (id) => {
  //     navigate(PATH_DASHBOARD.general.viewpermission(id))
  //   };
  //  ------------------handledlt--------------
  // const handledelete = (id) => {
  //   axios
  //     .delete(`${url}/api/admin/subadmins/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast.success('Agents Deleted Succesfully');
  //       }
  //       console.log(res, 'delete');
  //       fetchdata();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // ---------------handlesubmit--------------------
  const HandleSubmit = () => {
    try {
      const datatosend = new FormData();

      //   datatosend.append('password', password);
      datatosend.append('image', image);
      //   datatosend.append('_method', 'PUT');

      axios
        .post(`${url}/api/subadmin/websiteLogo/${id}`, datatosend, {
          headers: {
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpen(false);
            toast.success('Update Logo SuccesFully');
          }
          fetchdata();
          console.log(res, 'subadmin');
        })
        .catch((err) => {
          setOpen(false);
          console.log(err.Error, 'subadmin');
          //   alert(err);
        });
    } catch (error) {
      console.log(error, 'catch');
    }
  };

  // ----------------HandleUpdate-----------------------
  const handleEdit = (data) => {
    setcheckupdate(true);
    setphone(data.phone);
    setlocation(data.location);
    setemail(data.email);
    setOpen(true);
    setuniqeidupdate(data.id);
  };
  // const HandleUpdate = () => {
  //   console.log(password, 'updatedemo');
  //   try {
  //     const datatosend = new URLSearchParams();
  //     datatosend.append('email', email);
  //     datatosend.append('phone', phone);
  //     datatosend.append('location', location);
  //     datatosend.append('password', password);

  //     axios
  //       .put(`${url}/api/admin/subadmins/${uniqeidupdate}`, datatosend, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //       })
  //       .then((res) => {
  //         if (res.data.status === true) {
  //           setOpen(false);
  //           toast.success(res.data.response);
  //         }
  //         fetchdata();
  //         console.log(res, 'update');
  //       })
  //       .catch((err) => {
  //         setOpen(false);
  //         console.log(err.Error, 'updateerr');
  //         alert(err);
  //       });
  //   } catch (error) {
  //     console.log(error, 'catch');
  //   }
  // };

  return (
    <>
      <Grid container>
        <Toaster position="top-right" reverseOrder={false} />
        <Grid item xs={12} md={12}>
          <HeaderBreadcrumbs
            heading="Website Logo"
            // links={[{ name: '', href: '' }]}
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.general.website },
              { name: 'Website Logo Update' },
              // { name: !isEdit ? 'New user' : capitalCase(name) },
            ]}
            action={
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                Update Logo Website
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MaterialReactTable columns={columns} data={data} />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> {'Update Website Logo'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            type="file"
            fullWidth
            onChange={(e) => {
              const file = e.target.files[0];
              setimage(file);
            }}
          />
          {/* {checkupdate ? null : (
            <TextField
              margin="dense"
              type="file"
              fullWidth
              onChange={(e) => {
                const file = e.target.files[0];
                setimage(file);
              }}
            />
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={HandleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
