import * as Yup from 'yup';

 export const BlogSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  image: Yup.mixed().required('Blog Banner image is required'),
//   description: Yup.string().required('Description is required'),
  });

  export   const getDefaultValues = (currentBlog) => ({
      title: currentBlog?.title || '',
      image:  currentBlog?.image || '',
      // description: currentBlog?.description || '',
  })