import { useState } from 'react';
import { Grid, Container, Box } from '@mui/material';
import Page from '../../../components/Page';
// import axiosInstance from '../../../utils/axios';
import useSettings from '../../../hooks/useSettings';
import LoadingScreen from '../../../components/LoadingScreen';
import Travel from '../TravelQuotes/Travel';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Itineraries from '../Itineraries/Itineraries';

export default function Indexs() {
  const { themeStretch } = useSettings();

  const [loader, ] = useState(false);
  //   const currentUser = JSON.parse(localStorage.getItem('currentuser'));
  //   const navigate = useNavigate();


  return (
    <>
      {loader ? (
        <LoadingScreen />
      ) : (
        <Page title="Dashboard">
          <Container maxWidth={themeStretch ? false : 'xl'}>
            <HeaderBreadcrumbs heading="Welcome to Travel+CRM" links={[<></>]} action={<></>} />
            <Grid item xs={12} md={12} lg={12}>
              <Box className="my-5">
                <div className="row">
                  <div className="col-lg-6">
                    <Travel />
                  </div>
                  <div className="col-lg-6">
                    <Itineraries />
                  </div>
                </div>
              </Box>
            </Grid>
          </Container>
        </Page>
      )}
    </>
  );
}
