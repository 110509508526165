/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Card, Button, Container, Box, Tooltip, IconButton } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../../utils/axios';

export default function Client() {
  const [Data, setdata] = useState([]);
  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const GetYear = () => {
    axiosInstance
      .get('api/subadmin/client')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

  useEffect(() => {}, []);
  // const rows = [
  //     {
  //       id: 1,
  //       name: 'John Doe',
  //       email: 'john.doe@example.com',
  //       address: '123 Main St, Anytown, USA',
  //       phone: '+1-555-1234',
  //       member: 'Premium Member',
  //       tavel: 'Sightseeing Tour',
  //       taveli: '7-Day European Tour',
  //     },
  //     {
  //       id: 2,
  //       name: 'Jane Smith',
  //       email: 'jane.smith@example.com',
  //       address: '456 Elm St, Othertown, USA',
  //       phone: '+1-555-5678',
  //       member: 'Standard Member',
  //       tavel: 'Adventure Trip',
  //       taveli: '5-Day Mountain Expedition',
  //     },
  //     {
  //       id: 3,
  //       name: 'Alice Johnson',
  //       email: 'alice.johnson@example.com',
  //       address: '789 Oak St, Smalltown, USA',
  //       phone: '+1-555-9101',
  //       member: 'Basic Member',
  //       tavel: 'Cultural Tour',
  //       taveli: '10-Day Asian Heritage Tour',
  //     },
  //     // Add more rows as needed
  //   ];

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },

      {
        accessorKey: 'family_name',
        header: 'Name',
        size: 10,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 10,
      },
      {
        accessorKey: 'address',
        header: 'Address',
        size: 10,
      },
      {
        accessorKey: 'phone',
        header: 'Phone',
        size: 10,
      },
      {
        accessorKey: 'member_name',
        header: 'Members',
        size: 10,
      },
      {
        accessorKey: 'tavel',
        header: 'Travel Quotes',
        size: 10,
        Cell: ({ row }) => (
          <p>{row?.original?.travel_quote?.length}</p>
        )
      },
      {
        accessorKey: 'taveli',
        header: 'Tavel Itineraries',
        size: 10,
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`/dashboard/editclient/${id}`, { state: row?.original })}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`api/subadmin/client/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Deleted Succesfully');
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Page title="Travel+: Category And Services">
        <Toaster position="top-right" reverseOrder={false} />
        <Container maxWidth={themeStretch ? false : 24}>
          <HeaderBreadcrumbs
            heading="My Client"
            links={[<></>]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.dashboard.addclient}
              >
                Client
              </Button>
            }
          />

          <Card>
            <MaterialReactTable columns={columns} data={Data} />
          </Card>
        </Container>
      </Page>
    </>
  );
}
