/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo } from 'react';
import { Card, Container, Box, Tooltip, IconButton } from '@mui/material';
import useSettings from '../../../hooks/useSettings';
// import { getProducts } from '../../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../../utils/axios';

export default function PaymentTable({data,GetPayment}) {

  const { themeStretch } = useSettings();



  useEffect(() => {}, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },
      //   {
      //     accessorKey: 'media',
      //     header: 'Image',
      //     size: 10,
      // Cell: ({ row }) => {
      //   const imageUrl = row.original.media;
      //   return (
      //     <AvatarGroup max={2}>
      //       {imageUrl?.map((url, index) => (
      //         <Avatar key={index} alt="Product image" src={url?.media} />
      //       ))}
      //     </AvatarGroup>
      //   );
      // },
      //   },

      {
        accessorKey: 'payment_amount',
        header: 'Amount',
        size: 10,
      },

      {
        accessorKey: 'date',
        header: 'Date',
        size: 10,
      },

      {
        accessorKey: 'descriptition',
        header: 'Description',
        size: 10,
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              {/* <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`/dashboard/editservices/${id}` ,{state:row?.original})}>
                  <EditIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`api/subadmin/itineraries_payment/${id}`)
      .then((res) => {
        if (res) {
          toast.success('Payment Deleted Successfully');
          GetPayment();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Container maxWidth={themeStretch ? false : 24}>
        <Card>
          <MaterialReactTable columns={columns} data={data} />
        </Card>
      </Container>
    </>
  );
}
