/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function Commission(state) {
  const data = JSON.parse(localStorage.getItem('user_new'));

  const email = state?.state?.client?.email;
  const fields = {
    todayDate: { label: "Today's Date", colum: 12, type: 'date', required: true },
    agentName: { label: 'Agent Name', type: 'text', required: true },
    agentEmail: { label: 'Agent Email', type: 'email', required: true },
    agentPhone: { label: 'Agent Phone Number', type: 'text', required: true },
    reservationNumber: { label: 'Reservation Number', type: 'text', required: true },
    clientName: { label: 'Client Name', type: 'text', required: true },
    supplier: { label: 'Supplier', type: 'text', required: true },
    bookingDate: { label: 'Booking Date', type: 'date', required: true },
    arrivalDate: { label: 'Arrival Date', type: 'date', required: true },
    departureDate: { label: 'Departure Date', colum: 12, type: 'date', required: true },
    grossCommission: { label: 'Gross Commission', type: 'number', required: true },
    agentCommission: { label: 'Agent Commission', type: 'number', required: true },
  };

  const validationSchema = Yup.object().shape(
    Object.keys(fields).reduce((schema, key) => {
      const { required, type } = fields[key];
      if (required) {
        schema[key] =
          type === 'number'
            ? Yup.number().required(`${fields[key].label} is required`)
            : Yup.string().required(`${fields[key].label} is required`);
      }
      return schema;
    }, {})
  );

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = useMemo(
    () => ({
      todayDate: new Date().toISOString().split('T')[0],
      // eslint-disable-next-line prefer-template
      agentName: data?.fname + ' ' + data?.lname || '',
      agentEmail: data?.email || '',
      agentPhone: '',
      reservationNumber: '',
      clientName: state?.state?.client?.family_name || '',
      supplier: '',
      bookingDate: '',
      arrivalDate: state?.state?.arrival_date || '',
      departureDate: state?.state?.departure_date || '',
      gross_commision: '',
      agentCommission: '',
    }),
    [email, state]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const { id } = useParams();
  useEffect(() => {
    if (state) {
      reset(defaultValues);
    }
  }, [state, reset, defaultValues]);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key.replace(/([A-Z])/g, '_$1').toLowerCase(), data[key]);
      });
      formData.append('itinerary_id', id);
      formData.append('is_draft', 0);

      await axiosInstance.post('api/subadmin/commission', formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
          navigate(PATH_DASHBOARD.dashboard.commission);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
      console.error(error);
    }
  };
  const Convert = async (data) => {
    try {
      const formData = new FormData();
      Object.keys(fields).forEach((key) => {
        formData.append(key.replace(/([A-Z])/g, '_$1').toLowerCase(), data[key]);
      });
      formData.append('itinerary_id', id);
      formData.append('is_draft', 1);

      await axiosInstance.post('api/subadmin/commission', formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
          navigate(PATH_DASHBOARD.dashboard.commission);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Card>
        <FormProvider methods={methods}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <Grid container spacing={3}>
                    {Object.keys(fields).map((key) => (
                      <Grid item xs={12} md={fields[key].colum === 12 ? 12 : 6} key={key}>
                        <RHFTextField
                          name={key}
                          label={fields[key].label}
                          type={fields[key].type}
                          InputLabelProps={{ shrink: true }}
                          // placeholder={`Enter ${fields[key].label}`}
                        />
                      </Grid>
                    ))}
                    {/* <RHFTextField name="name" label="Name" InputLabelProps={{ shrink: true }} /> */}
                    <Grid item xs={6}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting}
                        onClick={handleSubmit(Convert)}
                      >
                        Save
                      </LoadingButton>
                    </Grid>
                    <Grid item xs={6}>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        loading={isSubmitting}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit Commission
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
