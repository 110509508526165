/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
/* eslint-disable react/self-closing-comp */
import $ from 'jquery';
import ReactJson from 'react-json-view';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axiosInstance from '../../utils/axios';
import { MyContext } from './context';

window.jQuery = $;
window.$ = $;

require('formBuilder/dist/form-render.min.js');
require('jquery-ui-sortable');

const FormRender = () => {
  const { id } = useParams();
  // const { form, setForm } = useContext(MyContext);

  const [form, setform] = useState([]);
  console.log(form);  
  // console.log(form1);
  const fatchData = async () => {
    await axiosInstance
      .get(`api/subadmin/form_data/${id}`)
      .then((res) => {
        // console.log(res?.data?.data?.form_data);

        setform(res?.data?.data?.form_data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [output, setOutput] = useState([]);
  //   console.log(output, 'output--------------->');
  const fb = createRef();
  let formRender;
  useEffect(() => {
    fatchData();
  }, []);
  useEffect(() => {
    // console.log(form);
    const formData = form;
    formRender = $(fb.current).formRender({ formData });
  }, [form]);

  //   useEffect(() => {
  //     console.log(fb);
  //   }, []);
  function saveData() {
    if (Object.keys($(fb.current)?.formRender('userData')).length > 0) {
      setOutput($(fb.current).formRender('userData'));
    }
  }
  return (
    <div>
      <h1>Your Form</h1>
      <form ref={fb}></form>
      {Object.keys(form).length > 0 && (
        <>
          {/* <button onClick={saveData} type="button">
            Submit Data
          </button> */}
          {Object.keys(output).length > 0 && <ReactJson src={JSON.parse(JSON.stringify(output))} />}
        </>
      )}
    </div>
  );
};

export default FormRender;
