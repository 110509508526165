import { Container } from '@mui/material'
import React from 'react'
import TabsI from '../Tabs/Tabs'

function UpdateItineraries() {
  return (
    <Container>

   <TabsI/>
    </Container>
  )
}

export default UpdateItineraries