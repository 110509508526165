

/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import {  Link as RouterLink, useNavigate } from 'react-router-dom';
import { Card, Button, Container, Box, Tooltip, IconButton} from '@mui/material';
import { RemoveRedEye } from '@mui/icons-material';
import { PATH_DASHBOARD } from '../../routes/paths';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../utils/axios';


export default function CategoryWeb() {
    const [data,setdata]=useState([])
  const { themeStretch } = useSettings();

  const navigate = useNavigate()
  const GetYear = () => {
    axiosInstance
      .get('/api/admin/categories')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);  
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

useEffect(()=>{

},[])
// const rows = [
//     {
//         id:1
//     }
// ]
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 10,
      },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Add Blog">
                <IconButton onClick={() => navigate(`/dashboard/editcategory/${id}` ,{state:row?.original})}>
                  <RemoveRedEye />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`/dashboard/editcategory/${id}` ,{state:row?.original})}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async(id) => {
   await axiosInstance
      .delete(`/api/admin/categories/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Deleted Succesfully');
          GetYear()
    
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
<>
  <Page title="Travel+: Category">
    <Toaster position="top-right" reverseOrder={false} />
    <Container maxWidth={themeStretch ? false : 24}>
      <HeaderBreadcrumbs
        heading="Category"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          {
            name: 'Website',
            href: '/dashboard/website',
          },
          { name: 'Category' },
        ]}
        action={
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            component={RouterLink}
            to={`/dashboard/addcategory`}
          >
            New Category
          </Button>
        }
      />

      <Card>
        <MaterialReactTable columns={columns} data={data} />
      </Card>
    </Container>
  </Page>
</>

  );
}
