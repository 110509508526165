/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';
import axiosInstance from '../../../utils/axios';

export default function SendEmail() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [items, setItems] = useState({});
  const [__htmls, setHtml] = useState('');
  const [template, setemplate] = useState({});

  const GetData = () => {
    axiosInstance
      .get(`api/subadmin/email_itineraries/${id}`)
      .then((response) => {
        if (response?.data) {
          setItems(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  console.log(template?.template ? 'faiz' : 'else', 'template');
  const GetEmail = () => {
    axiosInstance
      .get(`api/subadmin/agent_email/template/${id}`)
      .then((response) => {
        if (response?.data) {
          setemplate(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetData();
    GetEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorRef = useRef(null);
  const HandleData = async () => {
    try {
      const formData = new FormData();
      formData.append('email', items?.client?.email);
      formData.append('template', __htmls);
      formData.append('itinerary_id', id);

      await axiosInstance.post('api/subadmin/itinerary/agent_email', formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [editorContent, setEditorContent] = useState('');

  const handleClick = (key, value) => {
    const newContent = `<div><strong>${key}:</strong> ${value}</div>`;
    const updatedContent = editorContent + newContent;
    setEditorContent(updatedContent);

    const completeContent = `
      <header style="text-align: center; font-weight: bold;">
        <table style="border-collapse: collapse; width: 100%;" border="1">
          <tbody>
            <tr>
              <td>
                <h1>Welcome to Your Email Editor</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </header>
      <div>${updatedContent}</div>
      <footer style="text-align: center; font-size: small;">
        <table style="border-collapse: collapse; width: 100%;" border="1">
          <tbody>
            <tr>
              <td>
                <h2>&copy; 2024 Your Company</h2>
              </td>
            </tr>
          </tbody>
        </table>
      </footer>
    `;

    if (editorRef.current) {
      editorRef.current.setContent(completeContent);
    }
  };

  const handleEditorChange = (content) => {
    setHtml(content);
    console.log('Content was updated:', content);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1, padding: '10px' }}>
        {template?.template ? (
          <div style={{ marginBottom: '20px' }}>
            {Object.entries(items).map(
              ([key, value]) =>
                key !== 'id' &&
                key !== 'client' && (
                  <div
                    key={key}
                    // onClick={() => handleClick(key, value)}
                    style={{
                      padding: '8px',
                      margin: '4px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ccc',
                      cursor: 'pointer',
                    }}
                  >
                    <strong>
                      {key.charAt(0).toUpperCase() + key.slice(1)} : {value}
                    </strong>
                  </div>
                )
            )}
          </div>
        ) : (
          <div style={{ marginBottom: '20px' }}>
            {Object.entries(items).map(
              ([key, value]) =>
                key !== 'id' &&
                key !== 'client' && (
                  <div
                    key={key}
                    onClick={() => handleClick(key, value)}
                    style={{
                      padding: '8px',
                      margin: '4px',
                      backgroundColor: '#f0f0f0',
                      border: '1px solid #ccc',
                      cursor: 'pointer',
                    }}
                  >
                    <strong>{key.charAt(0).toUpperCase() + key.slice(1)}</strong>
                  </div>
                )
            )}
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: __htmls }} style={{ border: '1px solid #ccc', padding: '10px' }} />
      </div>

      <div style={{ flex: 1, margin: '0 auto', padding: '10px', borderLeft: '1px solid #ccc' }}>
        <Editor
          apiKey="4sn6jxk0pr227gvbz3ec72w7ftwrevzgdne4v5qr36eojysq"
          onInit={(evt, editor) => {
            editorRef.current = editor;
            editor.setContent(`<!-- Initial Content -->`);
          }}
          init={{
            plugins: [
              'anchor',
              'autolink',
              'charmap',
              'codesample',
              'emoticons',
              'image',
              'link',
              'lists',
              'media',
              'searchreplace',
              'table',
              'visualblocks',
              'wordcount',
              'checklist',
              'mediaembed',
              'casechange',
              'export',
              'formatpainter',
              'pageembed',
              'a11ychecker',
              'tinymcespellchecker',
              'permanentpen',
              'powerpaste',
              'advtable',
              'advcode',
              'editimage',
              'advtemplate',
              'ai',
              'mentions',
              'tinycomments',
              'tableofcontents',
              'footnotes',
              'mergetags',
              'autocorrect',
              'typography',
              'inlinecss',
              'markdown',
            ],
            toolbar:
              'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ],
          }}
          // eslint-disable-next-line no-unneeded-ternary
          initialValue={
            // eslint-disable-next-line no-unneeded-ternary
            template?.template
              ? template?.template
              : `<header style="text-align: center; font-weight: bold;">
<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 99.9001%;"></colgroup>
<tbody>
<tr>
<td>
<h1>Welcome to Your Email Editor</h1>
</td>
</tr>
</tbody>
</table>
</header>

<footer style="text-align: center; font-size: small;"><br><br><br>
<table style="border-collapse: collapse; width: 100%;" border="1"><colgroup><col style="width: 99.8984%;"></colgroup>
<tbody>
<tr>
<td>
<h2>&copy; 2024 Your Company</h2>
</td>
</tr>
</tbody>
</table>`
          }
          onEditorChange={handleEditorChange}
        />
        <Button onClick={HandleData}>Send Email</Button>
      </div>
    </div>
  );
}
