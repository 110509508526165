/* eslint-disable no-plusplus */
/* eslint-disable block-scoped-var */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */

import React, { useMemo} from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate, } from 'react-router';
import { Card, Grid, Stack, Container} from '@mui/material'; // Added MenuItem for Select
import { FormProvider, RHFTextField } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import RHFDescription from '../../components/hook-form/RHFDescription';

export default function AddServices() {

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const NewSubSchema = Yup.object().shape({

    name: Yup.string().required('First name is required'),
    pricegbp: Yup.number().required('Last name is required'),
    priceeur: Yup.number().required('priceeur is required'),

    pricemad: Yup.number().required('pricemad  is required'),
    description: Yup.string().required('description  is required'),
  });

  const defaultValues = useMemo(
    () => ({

      name: '',
      pricegbp: '',
      priceeur: '',
      pricemad: '',
      description: '',
    }),
    []
  );
  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
//   const { id } = useParams();
//   const idone = localStorage.getItem('e_id');
  const onSubmit = async (data) => {
    console.log(data);
    // Handle submission logic here
    try {
      const dorm = new FormData();
      dorm.append('description', data?.description);
      dorm.append('eur_price', data?.priceeur);
      dorm.append('mad_price', data?.pricemad);
      dorm.append('name', data?.name);
      dorm.append('price', data?.pricegbp);
    
      //  console.log(user?.id);

      await axiosInstance.post(`/api/admin/services`, dorm).then((response) => {
        console.log(response?.data, '=========');
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);
          // reset();
navigate(-1)
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };


  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Services" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="name" label=" Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="pricegbp" label="price (GBP)" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="priceeur" label="price (EUR)" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="pricemad" label="Price (MAD)" InputLabelProps={{ shrink: true }} />
                  <RHFDescription name="description" label="Description" multiline rows={3} />

                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    Add Services
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
