/* eslint-disable no-unused-vars */

/* eslint-disable arrow-body-style */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
import React, { useEffect, useMemo, useState } from 'react';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import toast, { Toaster } from 'react-hot-toast';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate, useParams } from 'react-router';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Tooltip,
  IconButton,
  Avatar,
} from '@mui/material';

import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
// import { useParams } from 'react-router';

export default function Aboutus() {
  const { id } = useParams();
  const url = 'https://api.dg.dev-iuh.xyz';
  const [data, setdata] = useState([]);
  const [dataone, setdataone] = useState([]);
    const navigate = useNavigate()
  const [uniqeidupdate, setuniqeidupdate] = useState([]);
  const [checkupdate, setcheckupdate] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState('');
  const [description, setdescription] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [image, setimage] = useState('');
  //   api/subadmin/permission/website
  const token = localStorage.getItem('accessToken');
  const handleClickOpen = () => {
    navigate(`/dashboard/addaboutus/${id}`)
    // setOpen(true);
    // setcheckupdate(false);
    // setemail('');
    // setTitle('');
    // setdescription('');
    // setpassword('');
    // setimage('');
    // setuniqeidupdate('');
  };
  const handleClose = () => {
    setOpen(false);
  };

  // -----------------------------getdata-------------------
  const fetchdata = () => {
    axiosInstance
      .get(`api/subadmin/permission/singlewebsite/${id}`)
      .then((response) => {
        // console.log(response,'response');
        // if (response.data.status === true) {
        setdata(response?.data?.data);
        // }
        // console.log(response, 'resp');
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const Website = () => {
    axiosInstance
      .get(`api/subadmin/about_us`)
      .then((response) => {
        if (response.data.status === true) {
          setdataone(response.data.data);
        }
        console.log(response, 'resp');
      })
      .catch((error) => {
        toast.error(error?.message);
        console.error(error);
      });
  };
  useEffect(() => {
    fetchdata();
    Website();
  }, []);
  // const filter = dataone?.filter((e)=>e?.id===id)
  // console.log(filter,'filter');

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'id',
        size: 10,
      },
      
      {
        accessorKey: 'content',
        header: 'About',
        size: 150,
      },
     
      {
        accessorKey: 'image',
        header: 'Image',
        size: 20,
        Cell: ({ row }) => (
            // console.log(`${url}/storage/${row?.original?.image}`)
            // eslint-disable-next-line react/jsx-no-target-blank
            <img src={`${row?.original?.image}`} alt='Hello'/>
            
          ),
      },

      //   {
      //     accessorKey: 'action',
      //     header: ' permissions',
      //     size: 200,
      //     Cell:({row})=>{
      //       const id = row.original.id;
      //       return(
      //         <Box sx={{ display: 'flex', justifyContent:'center' }}>
      //           <Tooltip arrow placement="left" title=" permissions">
      //           <IconButton >
      //               <VisibilityIcon onClick={() => handleviewproduct(id)} />
      //             </IconButton>
      //           </Tooltip>
      //         </Box>
      //       )
      //     }
      //   },
      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;
          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => handleEdit(row.original)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    []
  );
  // ---------viewpage-------------
  //   const handleviewproduct = (id) => {
  //     navigate(PATH_DASHBOARD.general.viewpermission(id))
  //   };
  //  ------------------handledlt--------------
  const handledelete = (id) => {
    axios
      .delete(`${url}/api/subadmin/about_us/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success('About Deleted Succesfully');
          fetchdata();
        }
        // console.log(res, 'delete');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // ---------------handlesubmit--------------------
  const HandleSubmit = () => {
    try {
      const datatosend = new FormData();
      datatosend.append('category_id', id);
      datatosend.append('title', title);
      datatosend.append('description', description);
      datatosend.append('image', image);

      axios
        .post(`${url}/api/subadmin/blogs`, datatosend, {
          headers: {
            Authorization: `Bearer ${token}`, // Example authorization header
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpen(false);
            toast.success('Blog Added Successfully');
          }
          fetchdata();
          console.log(res, 'subadmin');
        })
        .catch((err) => {
          setOpen(false);
          console.log(err.Error, 'subadmin');
          //   alert(err);
        });
    } catch (error) {
      console.log(error, 'catch');
    }
  };

  // ----------------HandleUpdate-----------------------
  const handleEdit = (data) => {
    navigate(`/dashboard/editabout/${data?.id}`,{state:data})
    // setcheckupdate(true);
    // setTitle(data.title);
    // setdescription(data.description);
    // setemail(data.email);
    // setOpen(true);
    // setuniqeidupdate(data.id);
  };
  const HandleUpdate = () => {

    try {
      const datatosend = new FormData();
      datatosend.append('category_id', id);
      datatosend.append('title', title);
      datatosend.append('description', description);
      datatosend.append('image', image);
      datatosend.append('_method', 'PUT');

      axios
        .put(`${url}/api/subadmin/blogs/${uniqeidupdate}`, datatosend, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setOpen(false);
            toast.success(res.data.response);
          }
          fetchdata();
          console.log(res, 'update');
        })
        .catch((err) => {
          setOpen(false);
          console.log(err.Error, 'updateerr');
          alert(err);
        });
    } catch (error) {
      console.log(error, 'catch');
    }
  };

  return (
    <>
      <Grid container>
        <Toaster position="top-right" reverseOrder={false} />
        <Grid item xs={12} md={12}>
          <HeaderBreadcrumbs
            heading="About Us"
            links={[{ name: '', href: '' }]}
            action={
              <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
                About Us
              </Button>
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <MaterialReactTable columns={columns} data={dataone} />
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> {checkupdate ? 'Update About Us' : 'Add About Us'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        

          
            <TextField
              margin="dense"
              type="file"
              fullWidth
              onChange={(e) => {
                const file = e.target.files[0];
                setimage(file);
              }}
            />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {checkupdate ?

              <Button onClick={HandleUpdate} color="primary">
            Save
          </Button> 
          :
              <Button onClick={HandleSubmit} color="primary">
            Save
          </Button> 
        }
        </DialogActions>
      </Dialog>
    </>
  );
}
