/* eslint-disable */
import React from 'react';
import { styled, Tab, Tabs, Box } from '@mui/material';
import { Icon } from '@iconify/react';
import { capitalCase } from 'change-case';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import Iconify from '../../../components/Iconify';
import useTabs from '../../../hooks/useTabs';
// import AddTravel from '../TravelQuotes/AddTravel';
import ItineraryTravelTab from '../Itineraries/ItineraryTravelTab';
import Payments from '../Itineraries/Payments';
import PaperWork from '../Itineraries/PaperWork';
import Commission from '../Itineraries/Commission';
import CommissionTables from './CommissionTable';
import CommissionTableSave from './CommissionTableSave';

function Commissions() {
  const TabsWrapperStyle = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      paddingRight: theme.spacing(3),
    },
  }));

  // Initialize currentTab with the default value
  const { currentTab, onChangeTab } = useTabs('Submitted Commission');

  const PROFILE_TABS = [
    {
      value: 'Submitted Commission',
      icon: <DriveEtaIcon />,
      component: <CommissionTables />,
    },
    {
      value: 'Saved Commission',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <CommissionTableSave />,
    },
  ];

  return (
    <>
      <TabsWrapperStyle>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {PROFILE_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
          ))}
        </Tabs>
      </TabsWrapperStyle>
      {PROFILE_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}

export default Commissions;
