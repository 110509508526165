/* eslint-disable no-unused-expressions */
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, Box, Typography } from '@mui/material';
// import ReactQuill from 'react-quill';
import axios from 'axios';
// import BaseUrl from '../../BaseUrl/BaseUrl';
import { fData } from '../../../utils/formatNumber';
// import { BlogSchema } from '../../AllSchema/Blog';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';
import 'react-quill/dist/quill.snow.css';

export default function EditPromotion() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader] = useState(false);
  // const [value, setValue] = useState('');
  // const [, setImageUrls] = useState([]);
  const { id } = useParams();
  const { state } = useLocation();
  const defaultValues = useMemo(
    () => ({
      link: state?.link || '',
      image: state?.image || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const BlogSchema = Yup.object().shape({
    link: Yup.string().required('link is required'),
    image: Yup.mixed().required(' image is required'),
    //   description: Yup.string().required('Description is required'),
  });
  const methods = useForm({
    resolver: yupResolver(BlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue: setFormValue,
    formState: { isSubmitting, errors },
  } = methods;
  console.log(errors);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setFormValue(
          'image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setFormValue]
  );

  const values = watch();

  const OnSubmit = async () => {
    const url = 'https://api.dg.dev-iuh.xyz';
    const formData = new FormData();
    try {
      // console.log(values, 'values-->>');
      const isStringType = typeof values?.image === 'string';
      if (isStringType) {
        formData.append('link', values?.link);
        formData.append('_method', 'PUT');
        // formData.append('image', values.image);
      } else {
        formData.append('link', values?.link);
        formData.append('_method', 'PUT');
        formData.append('image', values.image);
      }
      const temp = await localStorage.getItem('accessToken');

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${temp}`,
        },
      };

      const response = await axios.post(`${url}/api/admin/promotions/${id}`, formData, config);

      if (response?.data?.status === true) {
        enqueueSnackbar('Promotion Edited Successfully');
        reset();
        navigate(`/dashboard/promotion`);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  // const quillRef = useRef(null);

  // const imageHandler = useCallback(() => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.setAttribute('multiple', 'true');
  //   input.click();

  //   input.onchange = async () => {
  //     setLoader(true);
  //     const files = Array.from(input.files);
  //     if (files.length > 0) {
  //       const editor = quillRef.current.getEditor();
  //       const range = editor.getSelection();
  //       editor.blur();
  //       const temp = await localStorage.getItem('accessToken');
  //       const config = {
  //         headers: {
  //           'Content-Type': 'multipart/form-data',
  //           Authorization: ` Bearer ${temp}`,
  //         },
  //       };
  //       const uploadPromises = files.map((file) => {
  //         const formData = new FormData();
  //         formData.append('des_image', file);
  //         return axios
  //           .post('https://api.dg.dev-iuh.xyz/api/subadmin/blogImage', formData, config)
  //           .then((result) => result?.data?.des_image)
  //           .catch((error) => {
  //             setLoader(false);
  //             console.error('Error:', error);
  //             return null;
  //           });
  //       });

  //       const urls = await Promise.all(uploadPromises);
  //       urls.forEach((url) => {
  //         if (url) {
  //           setImageUrls((prevUrls) => [...prevUrls, url]);
  //           editor.insertEmbed(range.index, 'image', url);
  //           editor.setSelection(range.index + 1); // Move cursor to the right of the inserted image
  //         }
  //       });

  //       editor.focus();
  //       setLoader(false);
  //       // Restore focus after insertion
  //     }
  //   };
  // }, []);

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Edit Promotion" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(OnSubmit)}>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                  Promotion Image
                </Typography>
                <RHFUploadAvatar
                  name="image"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, *.gif
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Box>
              <Stack spacing={3} sx={{ py: 3 }}>
                <RHFTextField name="link" label="Promotion Link" />
              </Stack>
              <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting || loader}>
                Edit Promotion
              </LoadingButton>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
