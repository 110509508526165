import React, { useEffect, useState } from 'react';
import { styled, Tab, Tabs, Box } from '@mui/material';
// import { Icon } from '@iconify/react';
import { useParams } from 'react-router';
import { capitalCase } from 'change-case';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import Iconify from '../../../components/Iconify';
import useTabs from '../../../hooks/useTabs';
// import AddTravel from '../TravelQuotes/AddTravel';
import ItineraryTravelTab from '../Itineraries/ItineraryTravelTab';
import Payments from '../Itineraries/Payments';
import PaperWork from '../Itineraries/PaperWork';
import Commission from '../Itineraries/Commission';
import axiosInstance from '../../../utils/axios';

function TabsI() {
  const [Data, setdata] = useState([]);
  const { id } = useParams();
  const GetData = () => {
    axiosInstance
      .get(`api/subadmin/itineraries/${id}`)
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const TabsWrapperStyle = styled('div')(({ theme }) => ({
    width: '100%',
    marginBottom: '20px',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
      paddingRight: theme.spacing(3),
    },
  }));

  // Initialize currentTab with the default value
  const { currentTab, onChangeTab } = useTabs('Update Travel Itinerary');

  const PROFILE_TABS = [
    {
      value: 'Update Travel Itinerary',
      icon: <DriveEtaIcon state={Data} />,
      component: <ItineraryTravelTab state={Data} />,
    },
    {
      value: 'Payments',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <Payments state={Data} id={id} />,
    },
    {
      value: 'PaperWorks / Documents',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <PaperWork state={Data} id={id} />,
    },
    {
      value: 'Commission',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <Commission state={Data} />,
    },
  ];

  return (
    <>
      <TabsWrapperStyle>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {PROFILE_TABS.map((tab) => (
            <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
          ))}
        </Tabs>
      </TabsWrapperStyle>
      {PROFILE_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}

export default TabsI;
