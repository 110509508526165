/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Card, Button, Container, Box, Tooltip, IconButton } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../../utils/axios';

export default function Travel() {
  const [data, setdata] = useState([]);
  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const GetYear = () => {
    axiosInstance
      .get('api/subadmin/travel_quote')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

  // const rows = [
  //   {
  //       id: 1,
  //       name: 'faiz',
  //       client: 'Hello',
  //       traveltype: 'Type',
  //       destination: 'Door',
  //   }
  // ];
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },

      {
        accessorKey: 'title',
        header: 'Name',
        size: 10,
      },
      {
        accessorKey: 'client_id',
        header: 'client',
        size: 10,
        Cell: ({ row }) => <p>{row?.original?.client?.family_name}</p>,
      },
      {
        accessorKey: 'travel_type',
        header: 'traveltype',
        size: 10,
      },
      {
        accessorKey: 'destination',
        header: 'Destination',
        size: 10,
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`/dashboard/edittravel/${id}`, { state: row?.original })}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`api/subadmin/travel_quote/${id}`)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Deleted Succesfully');
          GetYear();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Page title="Travel+: Category And Services">
        <Toaster position="top-right" reverseOrder={false} />
        <Container maxWidth={themeStretch ? false : 24}>
          <HeaderBreadcrumbs
            heading="Travel Quotes"
            links={[<></>]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.dashboard.addtravel}
              >
                Travel Quote
              </Button>
            }
          />

          <Card>
            <MaterialReactTable columns={columns} data={data} />
          </Card>
        </Container>
      </Page>
    </>
  );
}
