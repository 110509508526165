/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
import toast, { Toaster } from 'react-hot-toast';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
import { Card, Container, Box, Tooltip, IconButton } from '@mui/material';
// import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
// import Page from '../../../components/Page';
// import Iconify from '../../../components/Iconify';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../../utils/axios';

export default function PaperTable() {
  const [data, setdata] = useState([]);
  const { themeStretch } = useSettings();

  // const navigate = useNavigate()
  const GetYear = () => {
    axiosInstance
      .get('/api/subadmin/itinerary/Document')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

  useEffect(() => {}, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },
      //   {
      //     accessorKey: 'media',
      //     header: 'Image',
      //     size: 10,
      // Cell: ({ row }) => {
      //   const imageUrl = row.original.media;
      //   return (
      //     <AvatarGroup max={2}>
      //       {imageUrl?.map((url, index) => (
      //         <Avatar key={index} alt="Product image" src={url?.media} />
      //       ))}
      //     </AvatarGroup>
      //   );
      // },
      //   },

      {
        accessorKey: 'document_path',
        header: 'Document Name',
        size: 10,
        Cell: ({ row }) => <a href={row?.original?.document_path}>{row?.original?.document_path}</a>,
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        Cell: ({ row }) => {
          const id = row.original.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              {/* <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => navigate(`/dashboard/editservices/${id}` ,{state:row?.original})}>
                  <EditIcon />
                </IconButton>
              </Tooltip> */}
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete(id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handledelete = async (id) => {
    await axiosInstance
      .delete(`api/subadmin/itinerary/Document/${id}`)
      .then((res) => {
        if (res.status) {
          toast.success('Deleted Succesfully');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Container maxWidth={themeStretch ? false : 24}>
        <Card>
          <MaterialReactTable columns={columns} data={data} />
        </Card>
      </Container>
    </>
  );
}
