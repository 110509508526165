import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

export default function EditPayment() {
  const { state } = useLocation();
  const [data, setdata] = useState([]);
  const NewSubSchema = Yup.object().shape({
    travelItinerary: Yup.string().required('Travel Itinerary is required'),
    dateOfReminder: Yup.string().required('Date of Reminder is required'),
    timeOfReminder: Yup.string().required('Time of Reminder is required'),
    notification: Yup.string().required('Notification is required'),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const defaultValues = {
    travelItinerary: state?.itinerary_id || '',
    dateOfReminder: state?.reminder_date || '',
    timeOfReminder: state?.reminder_time || '',
    notification: state?.notification || '',
  };

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('itinerary_id', data?.travelItinerary);
      formData.append('reminder_date', data?.dateOfReminder);
      formData.append('reminder_time', data?.timeOfReminder);
    //   formData.append('reminder_time', '06:30:00');
      formData.append('notification', data?.notification);
      formData.append('_method', 'PUT');

      await axiosInstance.post(`api/subadmin/payment_notification/${id}`, formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
          navigate('/dashboard/payment');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  const GetItnary = () => {
    axiosInstance
      .get('api/subadmin/itineraries')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetItnary();
  }, []);
  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Edit New Payment Notification" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  {/* <RHFTextField
                    name="travelItinerary"
                    label="Travel Itinerary"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Travel Itinerary"
                  /> */}
                  <RHFSelect
                    name="travelItinerary"
                    label="Select Itinerary"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((client) => (
                      <MenuItem key={client?.title} value={client?.id}>
                        {client?.title}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFTextField
                    name="dateOfReminder"
                    label="Date of Reminder"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Date of Reminder"
                  />

                  <RHFTextField
                    name="timeOfReminder"
                    label="Time of Reminder"
                    type="time"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Time of Reminder"
                  />

                  <RHFTextField
                    name="notification"
                    label="Notification"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Notification"
                    multiline
                    rows={4}
                  />

                  <Grid item xs={12}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Submit
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
