/* eslint-disable react/prop-types */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Button, Grid, Dialog, Stack, DialogActions, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
// import { useNavigate } from 'react-router';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import axiosInstance from '../../../utils/axios';
// import { PATH_DASHBOARD } from '../../../routes/paths';
export default function Dialogs(props) {
  const { enqueueSnackbar } = useSnackbar();
  //   const navigate = useNavigate();
  const { setOpen, open, id, GetPayment } = props;
  const NewSubSchema = Yup.object().shape({
    amount: Yup.number().required('amount is required'),
    description: Yup.string().required('description is required'),
  });
  const defaultValues = useMemo(
    () => ({
      amount: '',
      description: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,

    formState: { isSubmitting },
  } = methods;
  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    // console.log(data, 'data');
    const today = new Date().toLocaleDateString();
    // console.log(today);

    try {
      const payment = new FormData();

      payment.append(`itinerary_id`, id);
      payment.append(`payment_amount`, data?.amount);
      payment.append(`descriptition`, data?.description);
      payment.append(`date`, today);

      await axiosInstance.post(`api/subadmin/itineraries_payment`, payment).then((response) => {
        if (response.data?.response) {
          enqueueSnackbar(response.data?.response);
          handleClose();
          GetPayment();
          //   navigate(PATH_DASHBOARD.dashboard.payment);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogContent sx={{ width: '500px' }}>
          <HeaderBreadcrumbs heading="Create New Payment" links={[{ name: '', href: '' }]} />
          <Stack spacing={3}>
            <RHFTextField name="amount" label="Enter Amount" type="number" InputLabelProps={{ shrink: true }} />
            <RHFTextField
              name="description"
              label="Description"
              InputLabelProps={{ shrink: true }}
              multiline
              rows={4}
            />
          </Stack>
          {/* <div dangerouslySetInnerHTML={{ __html: videoUrl }} /> */}
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} md={12}>
            <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
              Create New Payment
            </LoadingButton>
          </Grid>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
