/* eslint-disable no-plusplus */
/* eslint-disable block-scoped-var */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */

import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Card, Grid, Stack, Container } from '@mui/material'; // Added MenuItem for Select
import { FormProvider, RHFTextField } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import RHFDescription from '../../components/hook-form/RHFDescription';

export default function EditServices() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const NewSubSchema = Yup.object().shape({
    name: Yup.string().required('name is required'),
    pricegbp: Yup.number().required('pricegbp is required'),
    priceeur: Yup.number().required('priceeur is required'),
    pricemad: Yup.number().required('pricemad  is required'),
    description: Yup.string().required('description  is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: state?.name || '',
      pricegbp: state?.price || '',
      priceeur: state?.eur_price || '',
      pricemad: state?.mad_price || '',
      description: state?.description || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const { id } = useParams();

  const onSubmit = async (data) => {
    console.log(data);
    // Handle submission logic here
    try {
      const dorm = new FormData();
      dorm.append('description', data?.description);
      dorm.append('name', data?.name);
      dorm.append('eur_price', data?.priceeur);
      dorm.append('mad_price', data?.pricemad);
      dorm.append('price', data?.pricegbp);
      dorm.append('_method', 'PUT');
      await axiosInstance.post(`/api/admin/services/${id}`, dorm).then((response) => {
        if (response.data?.message) {
          enqueueSnackbar(response.data?.message);

          navigate(-1);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit Services" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="name" label=" Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="pricegbp" label="price (GBP)" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="priceeur" label="price (EUR)" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name="pricemad" label="Price (MAD)" InputLabelProps={{ shrink: true }} />
                  <RHFDescription name="description" label="Description" multiline rows={3} />
                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Edit Services
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
