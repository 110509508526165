/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */

import React, { useMemo, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem, Button, Divider, Box, TextField } from '@mui/material';
// import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
import { RHFSelect, FormProvider, RHFTextField } from '../../../components/hook-form';
import { PATH_DASHBOARD } from '../../../routes/paths';

export default function Addclient() {
  const { state } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [Data, setdata] = useState([1]);
  const [Device, setDevice] = useState([]);
  const [emplyoee, setemployee] = useState([]);
  const [vehcile, setvehicle] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  // console.log(Data, 'state');
  const NewSubSchema = Yup.object().shape({
    roster: Yup.array()
      .of(
        Yup.object().shape({
          member: Yup.string().required('Member name is required'),
          date: Yup.string().required('date of birth is required'),
        })
      )
      .min(1, 'At least one member is required'),
    familyname: Yup.string().required('Family name is required'),
    Email: Yup.string().email('Invalid email address').required('Email is required'),
    Address: Yup.string().required('Address is required'),
    Phone: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]+$/, 'Phone number must be numeric')
      .length(10, 'Phone number must be exactly 10 digits'),
    agentNotes: Yup.string().notRequired(),
  });

  const defaultValues = useMemo(
    () => ({
      roster: Data?.map((e) => ({
        member: '',
        date:  '',
      })),
    }),

    [Data]
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = methods;

  const value = watch();


  useEffect(() => {
    if (Data) {
      reset(defaultValues);
    }
  }, [Data, reset, defaultValues]);
  // const idone = localStorage.getItem('e_id');
  const handleAdd = () => {
    console.log(value, 'values');
    if (value.roster.length < 4) {
      setValue('roster', [...value.roster, '']);
    }
  };

  const handleRemove = (index) => {
    // eslint-disable-next-line prefer-const
    let la = value.roster;
    la.splice(index, 1);
    setValue('roster', la);
  };

  const onSubmit = async (data) => {
    console.log(data, 'data');
    try {
      const dorm = new FormData();
      data.roster.forEach((item, index) => {
        dorm.append(`member_name[${index}]`, item.member);
        dorm.append(`age[${index}]`, item?.date);
      });
      dorm.append(`address`, data?.Address);
      dorm.append(`phone`, data?.Phone);
      dorm.append(`notes`, data?.agentNotes);
      dorm.append(`email`, data?.Email);
      dorm.append(`family_name`, data?.familyname);

      await axiosInstance.post(`api/subadmin/client`, dorm).then((response) => {
        if (response.data?.response) {
          enqueueSnackbar(response.data?.response);
          // reset();
          navigate(PATH_DASHBOARD.dashboard.client);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="lg">
      <HeaderBreadcrumbs heading="Create New Client" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={1.5}>
                  <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
                    {value?.roster?.map((item, index) => (
                      <Stack spacing={1.5} key={index}>
                        <Box
                          sx={{
                            display: 'grid',
                            columnGap: 2,
                            rowGap: 3,
                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                          }}
                        >
                          <RHFTextField
                            name={`roster[${index}].member`}
                            label="Member 1 Name"
                            InputLabelProps={{ shrink: true }}
                          />
                          <RHFTextField
                            name={`roster[${index}].date`}
                            label="Age"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Select Age"
                          />
                        </Box>
                        <Button
                          size="small"
                          color="error"
                          startIcon={<Iconify icon="eva:trash-2-outline" />}
                          onClick={() => handleRemove(index)}
                        >
                          Remove
                        </Button>
                      </Stack>
                    ))}
                  </Stack>
                  <Button
                    size="small"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={handleAdd}
                    sx={{ flexShrink: 0 }}
                  >
                    Add New Member
                  </Button>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={1.5}>
                  <RHFTextField name={`familyname`} label="Family Name" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name={`Email`} label="Email" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name={`Address`} label="Address" InputLabelProps={{ shrink: true }} />
                  <RHFTextField name={`Phone`} label="Phone" InputLabelProps={{ shrink: true }} />

                  <RHFTextField
                    name="agentNotes"
                    label="Notes"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Notes"
                    multiline
                    rows={4}
                  />

                  <Grid item xs={12}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Submit
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
