/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axiosInstance from '../../utils/axios';
import './style.css';

function Table() {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [uniqueLabels, setUniqueLabels] = useState([]);

  const fetchData = () => {
    axiosInstance
      .get(`api/subadmin/allWebContactus/${id}`)
      .then((response) => {
        const fetchedData = response?.data?.cantactInfo?.map((e) => JSON.parse(e?.data)).flat() || [];
        console.log(fetchedData, 'fetchedData');
        setData(fetchedData);

        const labels = fetchedData?.flatMap((e)=>e?.labels) || [];
        const uniqueLabels = [...new Set(labels)];

        // Create a single object with the unique labels
        const consolidatedObject = {
          labels: uniqueLabels,
        };
        setUniqueLabels(consolidatedObject)
        // Output the result
        console.log(consolidatedObject,"consolidatedObject");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  console.log(data, 'data');

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="container">
      <h1>Responsive Table</h1>
      <table className="rwd-table">
        <thead>
          <tr>{uniqueLabels.labels?.map((label, index) =>  <th key={index}>{label}</th>)}</tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.userData?.map((label, colIndex) => (
                <td key={colIndex} data-th={label}>
                  {label? label: 'Empty'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <h3>Resize Me</h3>
    </div>
  );
}

export default Table;
