/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/newline-after-import */
import $ from 'jquery';
import React, { createRef, useContext, useEffect, useState } from 'react';
import './formbuilder.css';
// import ReactJson from 'react-json-view';
import DeleteIcon from '@mui/icons-material/Delete';
// eslint-disable-next-line import/newline-after-import
import { Button, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import {  useParams } from 'react-router';
import { MyContext } from './context';
import Iconify from '../../components/Iconify';
import FormRender from './ShowBuilder';
import axiosInstance from '../../utils/axios';
window.jQuery = $;
window.$ = $;

require('jquery-ui-sortable');
require('formBuilder'); 

const FormBuilder = () => {
  const {id}=useParams()

  const { enqueueSnackbar } = useSnackbar();
  const fb = createRef();
  const [formBuilder, setFormBuilder] = useState(null);
  const { form, setForm } = useContext(MyContext);
  console.log(form,'form');
  
  useEffect(() => {
    if (!formBuilder?.formData) {
      setFormBuilder(
        $(fb.current).formBuilder({
          disabledActionButtons: ['data', 'clear', 'save'],
          formData: [],   
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function saveData() {
    // const url = 'https://api.dg.dev-iuh.xyz';
    try {
      // console.log(values, 'values-->>');
      // const temp = await localStorage.getItem('accessToken');
      const formData = new FormData();

      formData.append('website_id', id);
      formData.append('form_data', formBuilder.formData);


 

      const response = await axiosInstance.post(`api/subadmin/form_data`, formData);

      // console.log(response,'response---------->')
      if (response?.data?.status === true) {
        enqueueSnackbar(response?.data?.response);
        // reset();
        // navigate(`/dashboard/blog/${id}`);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message, {
        variant: 'error',
      });
      console.error(error);
    }
    setForm(formBuilder.formData);
  }
  function clearData() {
    formBuilder.actions.clearFields();
    setForm({});
  }
  // console.log(form);
  return (
    <div>
      <h1>Create Form</h1>
      <div ref={fb} />
      <div>
        {/* <div>
            {Object.keys(form).length > 0 &&
              <ReactJson src={JSON.parse(form)} />
            }
          </div> */}
        <div>
        <IconButton color="error" onClick={clearData}>
                  <DeleteIcon /> Clear
                </IconButton>
          <Button variant="contained" onClick={saveData}  startIcon={<Iconify icon="eva:plus-fill" />}>
            Save
          </Button>
        
       
        </div>
      </div>
      <FormRender/>
    </div>
  );
};

export default FormBuilder;
