/* eslint-disable no-else-return */
import { Icon } from '@iconify/react';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const getNavConfig = (userRole) => {
  const storedDataString = localStorage.getItem('setitem');
  const data = JSON.parse(storedDataString);
  

  // const  data =[
  //   {
  //     name:'website1'
  //   },
  //   {
  //     name:'website2'
  //   },
  //   {
  //     name:'website3'
  //   },
  // ]
  if (userRole === 'admin') {
    return [
      {
        subheader: 'Admin',
        items: [
          { title: 'Websites', path: PATH_DASHBOARD.general.website, icon: ICONS.ecommerce },
          { title: 'Agents', path: PATH_DASHBOARD.general.subadmin, icon: ICONS.user },
        ],
      },
      {
        subheader: 'Websites',
        items: [
          {
            title: 'Main Websites',
            path: PATH_DASHBOARD.weedowl.root,
            icon: ICONS.ecommerce,
            children: [
              { title: 'Services', path: '/dashboard/services' },
              { title: 'Category', path: '/dashboard/category' },
              { title: 'Promotion', path: '/dashboard/promotion' },
            ],
          },
        ],
      },
    ];
  } else {
    return [
      {
        subheader: 'Websites',
        items: data?.map(e=>({
            title: e?.title,
            path: PATH_DASHBOARD.weedowl.root,
            icon: ICONS.ecommerce,
            children: [
              { title: 'Contact Information', path: `/dashboard/websitechanges/${e?.id}`},
              { title: 'Logo', path: `/dashboard/logo/${e?.id}`},
              { title: 'Message', path: `/dashboard/message/${e?.id}` },
              { title: 'Supplier', path: `/dashboard/agentcategory/${e?.id}`},
              { title: 'FormBuilder', path: `/dashboard/formbuilder/${e?.id}`},
              { title: 'About Us', path: `/dashboard/aboutus/${e?.id}`},
              { title: 'Blogs', path: `/dashboard/agentblogs/${e?.id}`},
            ],
      }))
      },
      {
        items: [
          { title: 'Dashboard', path: PATH_DASHBOARD.dashboard.dashboard, icon: <Icon icon="ri:dashboard-line" />},
          {
            title: 'My Itineraries',
            path: PATH_DASHBOARD.dashboard.itineraries,
            icon:<EventNoteIcon/>,
            children: [
              { title: 'View Itineraries', path:PATH_DASHBOARD.dashboard.itineraries},
              // { title: 'Update Itineraries', path: '/dashboard/update-an-itinerary' },
             
            ],
          },
          { title: 'MY Travel QUOTES', path: PATH_DASHBOARD.dashboard.travel, icon: ICONS.user },
          { title: 'MY Clients', path: PATH_DASHBOARD.dashboard.client, icon: ICONS.user },
          { title: 'Commissions', path: PATH_DASHBOARD.dashboard.commission, icon: ICONS.user },
          { title: 'Payment Notification', path: PATH_DASHBOARD.dashboard.payment, icon: ICONS.user },
          
        ],
      },
      
    ];
  }
};

export default getNavConfig;
