/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
import MaterialReactTable from 'material-react-table';
// import toast, { Toaster } from 'react-hot-toast';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useMemo, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Card,
  Container,
  //  Box, Tooltip, IconButton
} from '@mui/material';
// import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
// import Iconify from '../../../components/Iconify';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import { getProducts } from '../../../redux/Weedowl/Weedowlproduct';
import axiosInstance from '../../../utils/axios';

export default function CommissionTables() {
  const [data, setdata] = useState([]);
  const { themeStretch } = useSettings();

  // const navigate = useNavigate();
  const GetYear = () => {
    axiosInstance
      .get('api/subadmin/commission')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          console.log(response?.data?.data);
          
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
  }, []);

  useEffect(() => {}, []);
  // const rows = [
  //     {
  //         id:1
  //     }
  // ]
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 50,
      },

      {
        accessorKey: 'travel',
        header: 'Travel Itinery',
        size: 10,
        Cell: ({ row }) => <p>{row?.original?.itinerary?.[0].title}</p>,
      },
      {
        accessorKey: 'today_date',
        header: 'date',
        size: 10,
      },
      {
        accessorKey: 'gross_commision',
        header: 'Commission',
        size: 10,
      },

      // {
      //   accessorKey: 'action',
      //   header: 'Action',
      //   size: 100,
      //   Cell: ({ row }) => {
      //     const id = row.original.id;

      //     return (
      //       <Box sx={{ display: 'flex', gap: '1rem' }}>
      //         <Tooltip arrow placement="left" title="Edit">
      //           <IconButton onClick={() => navigate(`/dashboard/editservices/${id}`, { state: row?.original })}>
      //             <EditIcon />
      //           </IconButton>
      //         </Tooltip>
      //         <Tooltip arrow placement="right" title="Delete">
      //           <IconButton color="error" onClick={() => handledelete(id)}>
      //             <DeleteIcon />
      //           </IconButton>
      //         </Tooltip>
      //       </Box>
      //     );
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // const handledelete = async (id) => {
  //   await axiosInstance
  //     .delete(`/api/admin/services/${id}`)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         toast.success('Deleted Succesfully');
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <Page title="Travel+: Category And Services">
        {/* <Toaster position="top-right" reverseOrder={false} /> */}
        <Container maxWidth={themeStretch ? false : 24}>
          <Card>
            <MaterialReactTable columns={columns} data={data} />
          </Card>
        </Container>
      </Page>
    </>
  );
}
