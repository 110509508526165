/* eslint-disable no-plusplus */
/* eslint-disable block-scoped-var */
/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */

import React, { useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useNavigate,  } from 'react-router';
import { Card, Grid, Stack, Container } from '@mui/material'; // Added MenuItem for Select
import { FormProvider, RHFTextField } from '../../components/hook-form';
import axiosInstance from '../../utils/axios';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';

export default function Addcategory() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const NewSubSchema = Yup.object().shape({
    name: Yup.string().required('name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: '',
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const onSubmit = async (data) => {
    console.log(data);
    // Handle submission logic here
    try {
      const dorm = new FormData();
      dorm.append('name', data?.name);

      //  console.log(user?.id);

      await axiosInstance.post(`api/admin/categories`, dorm).then((response) => {
        console.log(response?.data, '=========');
        if (response.data?.response) {
          enqueueSnackbar(response.data?.response);
          // reset();
          navigate('/dashboard/category');
        }
      });
    } catch (error) {
      // enqueueSnackbar(error?.message, {
      // variant: 'error',
      // });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Add Category" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="name" label="Name" InputLabelProps={{ shrink: true }} />

                  <Grid item xs={6} md={6}>
                    <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                      Add Category
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
