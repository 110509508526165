/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// import { LoadingButton } from '@mui/lab'
import { Card, Grid, Container, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Iconify from '../../../components/Iconify';
// import Travel from '../TravelQuotes/Travel';
import PaymentTable from './PaymentTable';
import Dialogs from './Dialog';
import { PATH_DASHBOARD } from '../../../routes/paths';
// eslint-disable-next-line import/newline-after-import, import/order
import { NavLink as RouterLink } from 'react-router-dom';;

export default function Payments({ id, state }) {
  const NewSubSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    title: state?.payment_key || '',
  };

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append('title', data?.title);
      formData.append('client', data?.client);
      formData.append('travel_type', data?.travelType);
      formData.append('destination', data?.destination);
      formData.append('arrival_date', data?.arrivalDate);
      formData.append('departure_date', data?.departureDate);
      formData.append('num_adults', data?.numAdults);
      formData.append('num_children', data?.numChildren);
      formData.append('trip_cost', data?.tripCost);
      formData.append('deposit_amount', data?.depositAmount);
      formData.append('agent_notes', data?.agentNotes);

      await axiosInstance.post('admin/travel', formData).then((response) => {
        if (response?.data?.message === 'Travel Details Added Successfully') {
          enqueueSnackbar(response?.data?.message);
          navigate('/dashboard/travel');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  const [open, setOpen] = useState(false);
  const [data, setdata] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const GetPayment = () => {
    axiosInstance
      .get('api/subadmin/itineraries_payment')
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          // console.log(response?.data);
          setdata(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetPayment();
  }, []);
  return (
    <>
      <Container maxWidth="lg">
        {/* <HeaderBreadcrumbs heading="Add Travel Details" links={[{ name: '', href: '' }]} /> */}
        <Card sx={{ p: 3 }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <RHFTextField
                  name="title"
                  label="Payment Keyphrase"
                  InputLabelProps={{ shrink: true }}
                  // placeholder="Enter Title"
                />
              </Grid>
              <Container sx={{ mt: 2 }}>There is no payment information available for this travel itinerary.</Container>
              <Grid item xs={12} md={12} sx={{ mb: 2 }}>
                <Box display="flex" justifyContent="center" gap={2}>
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    // component={RouterLink}
                    onClick={handleClickOpen}
                    to={''}
                  >
                    Create New Payment
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    component={RouterLink}
                    to={PATH_DASHBOARD.dashboard.addpayment}
                  >
                    Create Payment Notification
                  </Button>
                </Box>
              </Grid>

              <PaymentTable GetPayment={GetPayment} data={data} />
            </Grid>
          </FormProvider>
        </Card>
      </Container>

      <Dialogs setOpen={setOpen} open={open} id={id} GetPayment={GetPayment} />
    </>
  );
}
