/* eslint-disable no-plusplus */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable arrow-body-style */
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Stack, Container, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { DataDrop } from './Dropdowndata';

export default function EditTravel() {
  const [data, setdata] = useState([]);
  // const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const [state, setState] = useState([]);
  const NewSubSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    client_id: Yup.string().required('Client ID is required'),
    travel_type: Yup.string().required('Travel Type is required'),
    destination: Yup.string().required('Destination is required'),
    arrival_date: Yup.string().required('Arrival Date is required'),
    departure_date: Yup.string().required('Departure Date is required'),
    number_of_adults: Yup.string().required('Number of Adults is required'),
    number_of_children: Yup.string().required('Number of Children is required'),
    trip_cost: Yup.string().required('Trip Cost is required'),
    deposit_amount: Yup.string().required('Deposit Amount is required'),
    agent_notes: Yup.string().required('Agent Notes is required'),
    hotel_name: Yup.string(),
    number_of_rooms: Yup.string(),
    room_type: Yup.string(),
    ticket_type: Yup.string(),
    Genie: Yup.string(),
    flight: Yup.string(),
    ground_transportation: Yup.string(),
    add_ons: Yup.string(),
    number_of_staterooms: Yup.string(),
    stateroom_type: Yup.string(),
    ship: Yup.string(),
    deck: Yup.string(),
    departing_port: Yup.string(),
    all_port_stop: Yup.string(),
    ending_port: Yup.string(),
    cruise_dining: Yup.string(),
    supplier: Yup.string(),
    port_adventure: Yup.string(),
    number_of_days: Yup.string(),
    stateroom_number: Yup.string(),
    insurance_provider: Yup.string(),
    insurance_total: Yup.string(),
    insurance_notes: Yup.string(),
    activities: Yup.string(),
    express_pass: Yup.string(),
    package_1_hotel_name: Yup.string(),
    package_1_number_of_rooms: Yup.string(),
    package_1_room_details: Yup.string(),
    package_1_tickets: Yup.string(),
    package_1_total_price: Yup.string(),
    package_2_hotel_name: Yup.string(),
    package_2_number_of_rooms: Yup.string(),
    package_2_room_details: Yup.string(),
    package_2_tickets: Yup.string(),
    package_2_total_price: Yup.string(),
    package_3_hotel_name: Yup.string(),
    package_3_number_of_rooms: Yup.string(),
    package_3_room_details: Yup.string(),
    package_3_tickets: Yup.string(),
    package_3_total_price: Yup.string(),
    hotel_1_total: Yup.string(),
    hotel_1_number_of_rooms: Yup.string(),
    hotel_1_room_details: Yup.string(),
    hotel_1_name: Yup.string(),
    hotel_2_total: Yup.string(),
    hotel_2_number_of_rooms: Yup.string(),
    hotel_2_room_details: Yup.string(),
    hotel_2_name: Yup.string(),
    hotel_3_price: Yup.string(),
    hotel_3_number_of_rooms: Yup.string(),
    hotel_3_room_details: Yup.string(),
    hotel_3_name: Yup.string(),
  });

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      Genie: state?.Genie || '',
      activities: state?.activities || '',
      add_ons: state?.add_ons || '',
      agent_notes: state?.agent_notes || '',
      all_port_stop: state?.all_port_stop || '',
      arrival_date: state?.arrival_date || '',
      // client: state?.client || {},
      client_id: state?.client_id || '',
      cruise_dining: state?.cruise_dining || '',
      deck: state?.deck || '',
      departing_port: state?.departing_port || '',
      departure_date: state?.departure_date || '',
      deposit_amount: state?.deposit_amount || '',
      destination: state?.destination || '',
      ending_port: state?.ending_port || '',
      express_pass: state?.express_pass || '',
      flight: state?.flight || '',
      ground_transportation: state?.ground_transportation || '',
      hotel_1_name: state?.hotel_1_name || '',
      hotel_1_number_of_rooms: state?.hotel_1_number_of_rooms || '',
      hotel_1_room_details: state?.hotel_1_room_details || '',
      hotel_1_total: state?.hotel_1_total || '',
      hotel_2_name: state?.hotel_2_name || '',
      hotel_2_number_of_rooms: state?.hotel_2_number_of_rooms || '',
      hotel_2_room_details: state?.hotel_2_room_details || '',
      hotel_2_total: state?.hotel_2_total || '',
      hotel_3_name: state?.hotel_3_name || '',
      hotel_3_number_of_rooms: state?.hotel_3_number_of_rooms || '',
      hotel_3_price: state?.hotel_3_price || '',
      hotel_3_room_details: state?.hotel_3_room_details || '',
      hotel_name: state?.hotel_name || '',
      insurance_notes: state?.insurance_notes || '',
      insurance_provider: state?.insurance_provider || '',
      insurance_total: state?.insurance_total || '',
      number_of_adults: state?.number_of_adults || '',
      number_of_children: state?.number_of_children || '',
      number_of_days: state?.number_of_days || '',
      number_of_rooms: state?.number_of_rooms || '',
      number_of_staterooms: state?.number_of_staterooms || '',
      package_1_hotel_name: state?.package_1_hotel_name || '',
      package_1_number_of_rooms: state?.package_1_number_of_rooms || '',
      package_1_room_details: state?.package_1_room_details || '',
      package_1_tickets: state?.package_1_tickets || '',
      package_1_total_price: state?.package_1_total_price || '',
      package_2_hotel_name: state?.package_2_hotel_name || '',
      package_2_number_of_rooms: state?.package_2_number_of_rooms || '',
      package_2_room_details: state?.package_2_room_details || '',
      package_2_tickets: state?.package_2_tickets || '',
      package_2_total_price: state?.package_2_total_price || '',
      package_3_hotel_name: state?.package_3_hotel_name || '',
      package_3_number_of_rooms: state?.package_3_number_of_rooms || '',
      package_3_room_details: state?.package_3_room_details || '',
      package_3_tickets: state?.package_3_tickets || '',
      package_3_total_price: state?.package_3_total_price || '',
      port_adventure: state?.port_adventure || '',
      room_type: state?.room_type || '',
      ship: state?.ship || '',
      stateroom_number: state?.stateroom_number || '',
      stateroom_type: state?.stateroom_type || '',
      supplier: state?.supplier || '',
      ticket_type: state?.ticket_type || '',
      title: state?.title || '',
      travel_type: state?.travel_type || '',
      trip_cost: state?.trip_cost || '',
    }),
    [state]
  );

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),

    defaultValues,
  });
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
    reset,
  } = methods;

  const Getdata = () => {
    axiosInstance
      .get(`api/subadmin/travel_quote/${id}`)
      .then((response) => {
        // console.log(response.data)
        if (response?.data) {
          console.log(response?.data?.data);
          setState(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (state) {
      reset(defaultValues);
    }
  }, [state, reset, defaultValues]);
  const values = watch('travel_type');

  const GetYear = () => {
    axiosInstance
      .get('api/subadmin/client')
      .then((response) => {
        if (response?.data) {
          setdata(response?.data?.data);
          console.log(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetYear();
    Getdata();
  }, []);

  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });

      formData.append('_method', 'PUT');
      formData.append('travel_qoute_id', id);
      await axiosInstance.post(`api/subadmin/travel_quote/${id}`, formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
          navigate(PATH_DASHBOARD.dashboard.travel);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };
  function generateRandomCode(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const Convert = async (data) => {
    try {
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      const randomCode = generateRandomCode(10);

      formData.append('payment_key', randomCode);

      await axiosInstance.post(`api/subadmin/convert/itineraries/${id}`, formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
          navigate(PATH_DASHBOARD.dashboard.itineraries);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="sm">
      <HeaderBreadcrumbs heading="Edit Travel Details" links={[{ name: '', href: '' }]} />
      <Card>
        <FormProvider methods={methods}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ p: 3 }}>
                <Stack spacing={3}>
                  <RHFTextField name="title" label="Title" placeholder="Enter Title" />
                  <RHFSelect
                    name="client_id"
                    label="Select Client"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {data?.map((client) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.family_name}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="travel_type"
                    label="Travel Type"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                  >
                    {DataDrop.map((e) => {
                      return (
                        <MenuItem key={e.name} value={e.name}>
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Orlando® Ticket Only Quote' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'Walt Disney World® Resort Hotel Payment' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Tickets Only Quote' ? (
                    <RHFTextField name="flight" label="Flight" placeholder="Enter Flight" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Disneyland® Resort Ticket Only Quote' ||
                  values === 'Hotel Only' ||
                  values === 'Sandals® Resort Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'VAX Vacation Quote' ||
                  values === 'Walt Disney World® Resort Hotel Payment' ||
                  values === 'Walt Disney World® Resort Hotel Quotes' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="hotel_name" label="Hotel Name" placeholder="Enter Hotel Name" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Hotel Only' ||
                  values === 'Sandals® Resort Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'VAX Vacation Quote' ||
                  values === 'Walt Disney World® Resort Hotel Payment' ||
                  values === 'Walt Disney World® Resort Hotel Quotes' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="number_of_rooms" label="Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Hotel Only' ||
                  values === 'Sandals® Resort Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'VAX Vacation Quote' ||
                  values === 'Walt Disney World® Resort Hotel Payment' ||
                  values === 'Walt Disney World® Resort Hotel Quotes' ||
                  values === 'Walt Disney World® Resort Package Payment' ? (
                    <RHFTextField name="room_type" label="Room Type" placeholder="Enter Room Type" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Ticket Only Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Orlando® Ticket Only Quote' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Tickets Only Quote' ? (
                    <RHFTextField name="ticket_type" label="Ticket Type" placeholder="Enter Ticket Type" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Disneyland® Resort Ticket Only Quote' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Tickets Only Quote' ? (
                    <RHFTextField name="Genie" label="Genie" placeholder="Enter Genie" />
                  ) : (
                    ''
                  )}

                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'Hotel Only' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Package Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Sandals® Resort Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'VAX Vacation Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ||
                  values === 'Walt Disney World® Resort Hotel Payment' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="ground_transportation"
                      label="Ground Transportation"
                      placeholder="Enter Ground Transportation"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Disneyland® Resort Package Payment' ||
                  values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Disneyland® Resort Ticket Only Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'Hotel Only' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Package Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Sandals® Resort Quote' ||
                  values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Orlando® Ticket Only Quote' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ||
                  values === 'VAX Vacation Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ||
                  values === 'Walt Disney World® Resort Hotel Payment' ||
                  values === 'Walt Disney World® Resort Package Payment' ||
                  values === 'Walt Disney World® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Tickets Only Quote' ? (
                    <RHFTextField name="add_ons" label="Add-Ons" placeholder="Enter Add-Ons" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="number_of_staterooms" label="Number of Staterooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="stateroom_type" label="Stateroom Type" placeholder="Enter Stateroom Type" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="ship" label="Ship" placeholder="Enter Ship" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="deck" label="Deck" placeholder="Enter Deck" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="departing_port" label="Departing Port" placeholder="Enter Departing Port" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="all_port_stop" label="All Port Stop" placeholder="Enter All Port Stop" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="ending_port" label="Ending Port" placeholder="Enter Ending Port" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ? (
                    <RHFTextField name="cruise_dining" label="Cruise Dining" placeholder="Enter Cruise Dining" />
                  ) : (
                    ''
                  )}
                  {values === 'Hotel Only' ? (
                    <RHFTextField name="supplier" label="Supplier" placeholder="Enter Supplier" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="port_adventure" label="Port Adventure" placeholder="Enter Port Adventure" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="number_of_days" label="Number of Days" />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'Holland America® Cruise Line Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField
                      name="stateroom_number"
                      label="Stateroom Number"
                      placeholder="Enter Stateroom Number"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField
                      name="insurance_provider"
                      label="Insurance Provider"
                      placeholder="Enter Insurance Provider"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Carnival Cruise Line® Quote' ||
                  values === 'Celebrity Cruise Line® Quote' ||
                  values === 'Disney Cruise Line® Quote' ||
                  values === 'MSC Cruise Line® Quote' ||
                  values === 'Norwegian Cruise Line® Quote' ||
                  values === 'Princess Cruise Line® Quote' ||
                  values === 'Royal Caribbean Cruise Line® Quote' ||
                  values === 'Viking River Cruise Line® Quote' ||
                  values === 'Virgin Voyages' ? (
                    <RHFTextField name="insurance_total" label="Insurance Total" />
                  ) : (
                    ''
                  )}
                  {values === 'Insurance Quote' ? (
                    <RHFTextField name="insurance_notes" label="Insurance Notes" placeholder="Enter Insurance Notes" />
                  ) : (
                    ''
                  )}
                  {values === 'Package Quote' ||
                  values === 'Sandals® Resort Quote' ||
                  values === 'VAX Vacation Quote' ? (
                    <RHFTextField name="activities" label="Activities" placeholder="Enter Activities" />
                  ) : (
                    ''
                  )}
                  {values === 'Universal Orlando® Package Payment' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Universal Orlando® Ticket Only Quote' ||
                  values === 'Universal Studios Hollywood™ Package Quote' ? (
                    <RHFTextField name="express_pass" label="Express Pass" placeholder="Enter Express Pass" />
                  ) : (
                    ''
                  )}

                  {/* Packages */}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_1_hotel_name"
                      label="Package 1 Hotel Name"
                      placeholder="Enter Package 1 Hotel Name"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="package_1_number_of_rooms" label="Package 1 Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_1_room_details"
                      label="Package 1 Room Details"
                      placeholder="Enter Package 1 Room Details"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_1_tickets"
                      label="Package 1 Tickets"
                      placeholder="Enter Package 1 Tickets"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="package_1_total_price" label="Package 1 Total Price" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_2_hotel_name"
                      label="Package 2 Hotel Name"
                      placeholder="Enter Package 2 Hotel Name"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="package_2_number_of_rooms" label="Package 2 Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_2_room_details"
                      label="Package 2 Room Details"
                      placeholder="Enter Package 2 Room Details"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_2_tickets"
                      label="Package 2 Tickets"
                      placeholder="Enter Package 2 Tickets"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="package_2_total_price" label="Package 2 Total Price" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_3_hotel_name"
                      label="Package 3 Hotel Name"
                      placeholder="Enter Package 3 Hotel Name"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="package_3_number_of_rooms" label="Package 3 Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_3_room_details"
                      label="Package 3 Room Details"
                      placeholder="Enter Package 3 Room Details"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField
                      name="package_3_tickets"
                      label="Package 3 Tickets"
                      placeholder="Enter Package 3 Tickets"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Disneyland® Resort Packages Quote' ||
                  values === 'Universal Orlando® Resort Package Quotes' ||
                  values === 'Walt Disney World® Resort Package Quotes' ? (
                    <RHFTextField name="package_3_total_price" label="Package 3 Total Price" />
                  ) : (
                    ''
                  )}

                  {/* Hotels */}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_1_total" label="Hotel 1 Total" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_1_number_of_rooms" label="Hotel 1 Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField
                      name="hotel_1_room_details"
                      label="Hotel 1 Room Details"
                      placeholder="Enter Hotel 1 Room Details"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_1_name" label="Hotel 1 Name" placeholder="Enter Hotel 1 Name" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_2_total" label="Hotel 2 Total" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_2_number_of_rooms" label="Hotel 2 Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField
                      name="hotel_2_room_details"
                      label="Hotel 2 Room Details"
                      placeholder="Enter Hotel 2 Room Details"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_2_name" label="Hotel 2 Name" placeholder="Enter Hotel 2 Name" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_3_price" label="Hotel 3 Price" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_3_number_of_rooms" label="Hotel 3 Number of Rooms" />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField
                      name="hotel_3_room_details"
                      label="Hotel 3 Room Details"
                      placeholder="Enter Hotel 3 Room Details"
                    />
                  ) : (
                    ''
                  )}
                  {values === 'Walt Disney World® Resort Hotel Quotes' ? (
                    <RHFTextField name="hotel_3_name" label="Hotel 3 Name" placeholder="Enter Hotel 3 Name" />
                  ) : (
                    ''
                  )}
                  {/* // */}
                  <RHFTextField
                    name="destination"
                    label="Destination"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Destination"
                  />

                  <RHFTextField
                    name="arrival_date"
                    label="Arrival Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Arrival Date"
                  />

                  <RHFTextField
                    name="departure_date"
                    label="Departure Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Select Departure Date"
                  />

                  <RHFTextField
                    name="number_of_adults"
                    label="Number of Adults"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Number of Adults"
                  />

                  <RHFTextField
                    name="number_of_children"
                    label="Number of Children"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Number of Children"
                  />

                  <RHFTextField
                    name="trip_cost"
                    label="Trip Cost"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Trip Cost"
                  />

                  <RHFTextField
                    name="deposit_amount"
                    label="Deposit Amount"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Deposit Amount"
                  />

                  <RHFTextField
                    name="agent_notes"
                    label="Agent Notes"
                    InputLabelProps={{ shrink: true }}
                    placeholder="Enter Agent Notes"
                    multiline
                    rows={4}
                  />

                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Edit Travel Details
                    </LoadingButton>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      // loading={loader}
                      onClick={handleSubmit(Convert)}
                    >
                      Convert Itinerary
                    </LoadingButton>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
