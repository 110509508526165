export const DataDrop = [
  { name: 'Carnival Cruise Line® Quote' },
  { name: 'Celebrity Cruise Line® Quote' },
  { name: 'Disney Cruise Line® Quote' },
  { name: 'Disneyland® Resort Package Payment' },
  { name: 'Disneyland® Resort Packages Quote' },
  { name: 'Disneyland® Resort Ticket Only Quote' },
  { name: 'Holland America® Cruise Line Quote' },
  { name: 'Hotel Only' },
  { name: 'Insurance Quote' },
  { name: 'MSC Cruise Line® Quote' },
  { name: 'Norwegian Cruise Line® Quote' },
  { name: 'Package Quote' },
  { name: 'Payment Form' },
  { name: 'Princess Cruise Line® Quote' },
  { name: 'Royal Caribbean Cruise Line® Quote' },
  { name: 'Sandals® Resort Quote' },
  { name: 'Universal Orlando® Package Payment' },
  { name: 'Universal Orlando® Resort Package Quotes' },
  { name: 'Universal Orlando® Ticket Only Quote' },
  { name: 'Universal Studios Hollywood™ Package Quote' },
  { name: 'VAX Vacation Quote' },
  { name: 'Viking River Cruise Line® Quote' },
  { name: 'Virgin Voyages' },
  { name: 'Walt Disney World® Resort Hotel Payment' },
  { name: 'Walt Disney World® Resort Hotel Quotes' },
  { name: 'Walt Disney World® Resort Package Payment' },
  { name: 'Walt Disney World® Resort Package Quotes' },
  { name: 'Walt Disney World® Resort Tickets Only Quote' },
];
