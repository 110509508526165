/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Card, Container, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialReactTable from 'material-react-table';
import AddIcon from '@mui/icons-material/Add';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import useSettings from '../../hooks/useSettings';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import axiosInstance from '../../utils/axios';

export default function Agentcategory() {
  const Navigate = useNavigate();
  const { themeStretch } = useSettings();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [website, setwesbite] = useState([]);
  const [data, setdata] = useState([]);

  useEffect(() => {
    fatchData();
    fatchwebsite();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const fatchwebsite = async () => {
    await axiosInstance
      .get(`/api/subadmin/categories`)
      .then((res) => {
        setwesbite(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = website?.map((category) => ({
    label: category.name,
    value: category.id,
  }));
  const handleSelectChange = (selected) => {
    setSelected(selected);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fatchData = async () => {
    await axiosInstance
      .get(`/api/subadmin/subadmincategories`)
      .then((res) => {
        setdata(res?.data?.agent_categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 30,
      },

      {
        accessorKey: 'url',
        header: 'Url',
        size: 100,
        Cell: ({ row }) => (
          // console.log(row?.original?.subadmin?.permissions[0]?.id)
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            href={`${row?.original?.subadmin?.permissions[0]?.url}/?websiteid=${row?.original?.subadmin?.permissions[0]?.id}&agentid=${row?.original?.subadmin?.id}`}
            target="_blank"
          >
            {`${row?.original?.subadmin?.permissions[0]?.url}/?websiteid=${row?.original?.subadmin?.permissions[0]?.id}&agentid=${row?.original?.subadmin?.id}`}
          </a>
        ),
      },
      {
        accessorKey: 'category',
        header: 'Category',
        size: 100,
        Cell: ({ row }) => (
          // eslint-disable-next-line react/jsx-no-target-blank
          <p>{row?.original?.category?.name}</p>
        ),
      },

      {
        accessorKey: 'action',
        header: 'Action',
        size: 100,
        // eslint-disable-next-line arrow-body-style
        Cell: ({ row }) => {
          //   const id = row.original.pivot.id;

          return (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <Tooltip arrow placement="right" title="Add Blog">
                <IconButton color="success" onClick={() => Navigate(`/dashboard/blog/${row?.original?.id}`)}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
              <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handledelete('')}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    []
  );
  const handledelete = async (id) => {
    try {
      await axiosInstance
        .delete(`/api/admin/subadmins/permission/${id}`)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.response);
            fatchData();
          }
          console.log(res, 'permission');
        })
        .catch((err) => {
          console.log(err, 'dlt');
          toast.error(err.message);
        });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  // ------------------handlesubmit---------------------------
  const handleSubmit = () => {
    try {
      if (selected === '') {
        toast.error('please Select the products');
      } else {
        const fromdata = new FormData();
        selected.map((file) => fromdata.append('category_id[]', file.value));
        axiosInstance
          .post(`/api/subadmin/subadmincategories`, fromdata)
          .then((res) => {
            console.log(res, 'adsadasproduct');
            if (res.data.status === true) {
              toast.success(res.data.response);
              setSelected([]);
              setOpen(false);
              fatchData();
            }
          })
          .catch((err) => {
            toast.error(err.message);
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Page title="Category">
      <Toaster position="top-right" reverseOrder={false} />
      <Container maxWidth={themeStretch ? false : 24}>
        <HeaderBreadcrumbs
          heading="Category"
          links={[<></>]}
          action={
            <Button variant="contained" onClick={handleClickOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
              Category
            </Button>
          }
        />
        <Card>
          <MaterialReactTable columns={columns} data={data} />
        </Card>
      </Container>
      <Modal
        show={open}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: '1378' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select isMulti options={options} onChange={handleSelectChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
}
