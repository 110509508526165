/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Card, Grid, Container } from '@mui/material';
// import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { FormProvider } from '../../../components/hook-form';
import axiosInstance from '../../../utils/axios';
// import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// import Iconify from '../../../components/Iconify';
// import Travel from '../TravelQuotes/Travel';
// import PaymentTable from './PaymentTable';
import PaperTable from './PaperTable';
// import { PATH_DASHBOARD } from '../../../routes/paths';

export default function PaperWork({ id }) {
  const [image, setimage] = useState({});
  console.log(image);
  const NewSubSchema = Yup.object().shape({
    // title: Yup.string().required('Title is required'),
  });

  // const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = {
    // title: '',
  };

  const methods = useForm({
    resolver: yupResolver(NewSubSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('document_path', image);
      formData.append('itinerary_id', id);

      // formData.append('_method', 'PUT');
      // formData.append('id', 1);
      await axiosInstance.post(`api/subadmin/itinerary/Document`, formData).then((response) => {
        if (response?.data?.response) {
          enqueueSnackbar(response?.data?.response);
          // navigate(PATH_DASHBOARD.dashboard.itineraries);
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="lg">
      {/* <HeaderBreadcrumbs heading="Add Travel Details" links={[{ name: '', href: '' }]} /> */}
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ gap: 2 }}>
            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center', marginBottom: '5px', gap: 2 }}>
              <input type="file" onChange={(e) => setimage(e.target.files[0])} />
              <LoadingButton
                type="submit"
                sx={{ width: '200px' }}
                variant="contained"
                size="large"
                loading={isSubmitting}
              >
                Upload
              </LoadingButton>
            </Grid>

            <PaperTable />
          </Grid>
        </FormProvider>
      </Card>
    </Container>
  );
}
