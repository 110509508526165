import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
// import MainLayout from '../layouts/main';
import FormBuilder from '../pages/dashboard/Formbuilder';
import DashboardLayout from '../layouts/dashboard';

import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import Websitechange from '../pages/dashboard/Websitechanges/Websitechange';
import Logos from '../pages/dashboard/Websitechanges/Logos';
// import Catogery from '../pages/dashboard/Catogery';
import Addcategory from '../pages/dashboard/Addcategory';
import Editcategory from '../pages/dashboard/Editcategory';
import AddServices from '../pages/dashboard/AddServices';
import EditServices from '../pages/dashboard/EditServices';
import Services from '../pages/dashboard/Service';
import Message from '../pages/dashboard/Message';
import CategoryWeb from '../pages/dashboard/Category';
import Agentcategory from '../pages/dashboard/Agentcategory';
import Blog from '../pages/dashboard/Blog';
import AddBlog from '../pages/dashboard/AddBlog';
import EditBlog from '../pages/dashboard/EditBlog';
// import About from 'src/pages/About';
import AgentBlogs from '../pages/dashboard/Blog/Blog';
import Aboutus from '../pages/dashboard/About/About';
import AddAgentBlog from '../pages/dashboard/Blog/AddBlog';
import AddAboutus from '../pages/dashboard/About/AddAbout';
import EditAbout from '../pages/dashboard/About/EditAbout';
import EditBlogAgenet from '../pages/dashboard/Blog/EditBlog';
import Promotion from '../pages/dashboard/Promotion/Promotion';
import EditPromotion from '../pages/dashboard/Promotion/EditPromotion';
import AddPromotion from '../pages/dashboard/Promotion/AddPromotion';
import Indexs from '../pages/dashboard/Dashboard';
import { PATH_DASHBOARD } from './paths';
import Itineraries from '../pages/dashboard/Itineraries/Itineraries';
import AddTravel from '../pages/dashboard/TravelQuotes/AddTravel';
import EditTravel from '../pages/dashboard/TravelQuotes/EditTravel';
import UpdateItineraries from '../pages/dashboard/Itineraries/UpdateItineraries';
import Travel from '../pages/dashboard/TravelQuotes/Travel';
import Client from '../pages/dashboard/Client/Client';
import Addclient from '../pages/dashboard/Client/Addclient';
import Editclient from '../pages/dashboard/Client/Editclient';
// import Commission from '../pages/dashboard/Itineraries/Commission';
import Commissions from '../pages/dashboard/Commission/Commission';
import Payments from '../pages/dashboard/Payment/Payment';
import AddPayment from '../pages/dashboard/Payment/AddPayment';
import EditPayment from '../pages/dashboard/Payment/EditPayment';
import SendEmail from '../pages/dashboard/Itineraries/SendEmail';

// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/admin/login', element: <Login /> },
    { path: '/agent/login', element: <LoginAgent /> },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'website', element: <Website /> },
        { path: PATH_DASHBOARD.dashboard.dashboard, element: <Indexs /> },
        { path: PATH_DASHBOARD.dashboard.itineraries, element: <Itineraries /> },
        { path: PATH_DASHBOARD.dashboard.addtravel, element: <AddTravel /> },
        { path: `edittravel/:id`, element: <EditTravel /> },
        { path: `travel`, element: <Travel /> },
        { path: PATH_DASHBOARD.dashboard.client, element: <Client /> },
        { path: PATH_DASHBOARD.dashboard.addclient, element: <Addclient /> },
        { path: PATH_DASHBOARD.dashboard.commission, element: <Commissions /> },
        { path: 'editclient/:id', element: <Editclient /> },
        { path: 'subadmin', element: <Index /> },
        { path: 'sendmail/:id', element: <SendEmail /> },
        { path: 'editpayment/:id', element: <EditPayment /> },
        { path: 'update-an-itinerary/:id', element: <UpdateItineraries /> },
        { path: 'subadmin/Viewpermission:id', element: <Viewpermission /> },
        { path: 'demo', element: <Demo /> },
        { path: 'payment', element: <Payments /> },
        { path: 'addpayment', element: <AddPayment /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'websitechanges/:id', element: <Websitechange /> },
        { path: 'logo/:id', element: <Logos /> },
        { path: 'message/:id', element: <Message /> },
        { path: 'services', element: <Services /> },
        { path: 'category', element: <CategoryWeb /> },
        { path: 'blog/:id', element: <Blog /> },
        { path: 'addblog/:id', element: <AddBlog /> },
        { path: 'editblog/:id', element: <EditBlog /> },
        { path: 'addcategory', element: <Addcategory /> },
        { path: 'formbuilder/:id', element: <FormBuilder /> },
        { path: 'editcategory/:id', element: <Editcategory /> },
        { path: 'agentcategory/:id', element: <Agentcategory /> },
        { path: 'editservices/:id', element: <EditServices /> },
        { path: 'addservices', element: <AddServices /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'agentblogs/:id', element: <AgentBlogs /> },
        { path: 'aboutus/:id', element: <Aboutus /> },
        { path: 'editabout/:id', element: <EditAbout /> },
        { path: 'editagentblog/:id', element: <EditBlogAgenet /> },
        { path: 'addagentblogs/:id', element: <AddAgentBlog /> },
        { path: 'addaboutus/:id', element: <AddAboutus /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        { path: 'promotion', element: <Promotion /> },
        { path: 'editpromotion/:id', element: <EditPromotion /> },
        { path: 'addpromotion', element: <AddPromotion /> },
        {
          path: 'Weedowl',
          children: [
            { element: <Navigate to="/dashboard/weedowl/product" replace />, index: true },
            { path: 'product', element: <Product /> },
            { path: 'singleproduct/add', element: <NewProduct /> },
            { path: 'singleproduct/edit:id', element: <EditProduct /> },
            { path: 'category', element: <Category /> },
            { path: 'user', element: <User /> },
            { path: 'order', element: <Order /> },
            { path: 'offer', element: <Offer /> },
            { path: 'offer/product:id', element: <Productmanage /> },
            { path: 'newsletter', element: <NewLetter /> },
            { path: 'newsletter/add', element: <NewLetterAdd /> },
            { path: 'newsletter/edit:id', element: <NewLetterEdit /> },
          ],
        },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const LoginAgent = Loadable(lazy(() => import('../pages/auth/LoginAgent')));

// DASHBOARD

// GENERAL
const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const Website = Loadable(lazy(() => import('../pages/dashboard/Website')));
const Index = Loadable(lazy(() => import('../pages/dashboard/Subadmin/Index')));
const Viewpermission = Loadable(lazy(() => import('../pages/dashboard/Subadmin/Viewpermission')));
const Demo = Loadable(lazy(() => import('../pages/dashboard/Demo')));
//  weedowl
const Product = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Product/Index')));
const NewProduct = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Product/Addproduct')));
const EditProduct = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Product/Editproduct')));
const Category = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Catagory/Index')));
const User = Loadable(lazy(() => import('../pages/dashboard/Weedowl/User/Index')));
const Order = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Order/Index')));
const Offer = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Offer/Index')));
const Productmanage = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Offer/Productmanage')));
const NewLetter = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Newsletter/Index')));
const NewLetterAdd = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Newsletter/AddnewsLetter')));
const NewLetterEdit = Loadable(lazy(() => import('../pages/dashboard/Weedowl/Newsletter/UpdateNewsletter')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
